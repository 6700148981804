import { Injectable } from '@angular/core';
import { FacilityInformation } from './models/facility-information';
import { VacancySystemInfoItems } from '../../../app/services/servicer/models/vacancy-system';

/**
 * 施設情報のサービス.
 */
@Injectable()
export class FacilityService {


  private selectedFacility: VacancySystemInfoItems;
  private lastUpdate: string; // 更新日時
  private vacancyImage: string; //満空バンド画像のファイル名
  private facilityInformation: FacilityInformation = null; // 施設情報
  private facilityInfoIsUpdating: boolean = false; // 施設情報の更新状況フラグ
  private facilityValue: number;  // 満空状態値
  private login_user_id: number = null; // ログインユーザーID
  private isUseVacancySystem: boolean = false; // 満空情報システム使用フラグ

  constructor(
  ) {
  }


  /**
   * 選択された施設情報を取得する.
   */
   getFacility(): VacancySystemInfoItems {
    return this.selectedFacility;
  }

  /**
   * 選択された施設情報をを削除する.
   */
   deleteFacility() {
    this.selectedFacility = null;
  }
  
  /**
   * 選択された施設情報を保存する.
   */
   saveFacility(facility: VacancySystemInfoItems) {
    this.selectedFacility = facility;
  }

  /**
   * ホーム画面で取得した更新日時を保存する.
   */
   setLastUpdate(lastUpdate: string) {
    this.lastUpdate = lastUpdate;
  }
  
  /**
   * ホーム画面で取得した更新日時を取得する.
   */
   getLastUpdate(): string {
    return this.lastUpdate;
  }

  /**
   * ホーム画面で取得した満空バンド画像ファイルのパスを保存する.
   */
   setVacancyImagePath(vacancyImage: string) {
    this.vacancyImage = vacancyImage;
  }

  /**
   * ホーム画面で取得した満空バンド画像ファイルのパスを取得する.
   */
   getVacancyImagePath(): string {
    return this.vacancyImage;
  }

  /**
   * ホーム画面で取得した施設情報を保存する.
   */
   setInfomation(facilityInformation: FacilityInformation) {
    this.facilityInformation = facilityInformation;
  }

  /**
   * ホーム画面で取得した施設情報を取得する.
   */
   getInfomation(): FacilityInformation {
    return this.facilityInformation;
  }

  /**
   *  施設情報の更新状況フラグを保存する.
   */
   setInfoIsUpdating(facilityInfoIsUpdating: boolean) {
    this.facilityInfoIsUpdating = facilityInfoIsUpdating;
  }

  /**
   *  施設情報の更新状況フラグを取得する.
   */
   getInfoIsUpdating(): boolean {
    return this.facilityInfoIsUpdating;
  }

  /**
   *  満空状態値を保存する.
   */
   setStateValue(facilityValue: number) {
    this.facilityValue = facilityValue;
  }

  /**
   *  満空状態値を取得する.
   */
   getStateValue(): number {
    return this.facilityValue;
  }

  /**
   *  ログインユーザーIDを保存する.
   */
   setLogin_user_id(login_user_id: number) {
    this.login_user_id = login_user_id;
  }

  /**
   *  ログインユーザーIDを取得する.
   */
   getLogin_user_id(): number {
    return this.login_user_id;
  }

  /**
   *  満空情報システム使用フラグを保存する.
   */
   setIsUseVacancySystem(isUseVacancySystem: boolean) {
    this.isUseVacancySystem = isUseVacancySystem;
  }

  /**
   *  満空情報システム使用フラグを取得する.
   */
   getIsUseVacancySystem(): boolean {
    return this.isUseVacancySystem;
  }
  
}
