import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ServerlessApiProvider } from '../serverless-api';
import { HttpClient } from '@angular/common/http';
import { FacilityReserveHistory } from './models/facility-reserve-history';
import { FacilityReserveHistoryGetApiResponse, FacilityReserveHistoryUpdateApiResponse } from './models/facility-reserve-history-api-response';
import { FacilityReserveHistoryGetApiParameter } from './models/facility-reserve-history-api-parameter';

const BASE_URL = '/v1/facility/facilityReserveHistory';
const UPDATE_URL = `${BASE_URL}/update`;

/**
 * [満空情報]予約履歴のプロバイダー.
 */
@Injectable()
export class FacilityReserveHistoryServiceProvider extends ServerlessApiProvider {

  private sessionSource: BehaviorSubject<FacilityReserveHistory> = new BehaviorSubject<FacilityReserveHistory>(null);

  constructor(
    public http: HttpClient
  ) {
    super(http);
  }

  /**
   * セッションを取得する.
   */
   getSession(): FacilityReserveHistory {
    return this.sessionSource.getValue();
  }

  /**
   * セッションを削除する.
   */
  deleteSession() {
    this.sessionSource.next(null);
  }

  /**
   * セッションを保存する.
   */
  saveSession(session: FacilityReserveHistory) {
    this.sessionSource.next(session);
  }

  /**
   * 予約履歴一覧取得処理.
   * 
   * @param facilityId 施設ID
   * @param startDt 検索範囲開始日時
   * @param endDt 検索範囲終了日時
   * @returns Observable<FacilityReserveHistoryGetApiResponse>
   */
  public getHistory(startDt: number, endDt: number, facilityId?: number): Observable<FacilityReserveHistoryGetApiResponse> {
    const params = { facilityId: facilityId, startDt: startDt, endDt: endDt } as FacilityReserveHistoryGetApiParameter;
    if (facilityId) {
      params["facilityId"] = facilityId;
    }
    return this.post(BASE_URL, params);
  }

  /**
   * 予約履歴の更新処理.
   * 
   * @param facilityId 施設ID
   * @param userId ユーザーID
   * @param pickUpPointCode 乗車地コード
   * @param pickUpPointName 乗車地名
   * @param rideCount 乗車人数
   * @param reserveDt 予約日時
   * @returns Observable<FacilityReserveHistoryUpdateApiResponse>
   */
  public update(facilityId: number, userId: number, pickUpPointCode: string, pickUpPointName: string, rideCount: number, reserveDt: number ): Observable<FacilityReserveHistoryUpdateApiResponse> {
    const params = { 
      facilityId: facilityId,
      userId: userId,
      pickUpPointCode: pickUpPointCode,
      pickUpPointName: pickUpPointName,
      rideCount: rideCount,
      reserveDt: reserveDt
     } 
    return this.post(UPDATE_URL, params);
  }
}


