import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';

import { MyApp } from './app.component';

import { CustomLoadingComponentModule } from '../components/custom-loading/custom-loading.module';

import { LoadingProvider } from '../providers/loading/loading';
import { ReservationsGetScheduleProvider } from '../providers/reservations-get-schedule/reservations-get-schedule';
import { ManageStorageAroundReservationCardsService } from '../providers/servicer/reservations-check-status';
import { AppMonitorV2ServiceProvider } from '../providers/servicer/app-monitor-v2-service';
import { StationV2ServiceProvider } from '../providers/servicer/station-v2-service';
import { UserV2ServiceProvider } from '../providers/servicer/user-v2-service';
import { MessageLoader } from '../providers/servicer/message-loader';
import { UserVehicleV2ServiceProvider } from '../providers/servicer/user-vehicle-v2-service';
import { SavePageInfo } from './services/savePageInfo';
import { CacheUpdateService } from '../providers/servicer/cache-update-service';
import { StaticTableLoader } from '../app/services/StaticTableLoader';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FacilitySettingServiceProvider } from '../providers/serverless-api/facility/facility-setting-service';
import { FacilityInformationServiceProvider } from '../providers/serverless-api/facility/facility-information-service';
import { FacilityStatusTypeServiceProvider } from '../providers/serverless-api/facility/facility-status-type-service';
import { FacilitySummaryViewHistoryServiceProvider } from '../providers/serverless-api/facility/facility-summary-view-history-service';
import { FacilityDetailViewHistoryServiceProvider } from '../providers/serverless-api/facility/facility-detail-view-history-service';
import { FacilityReserveHistoryServiceProvider } from '../providers/serverless-api/facility/facility-reserve-history-service';
import { FacilityService } from '../providers/serverless-api/facility/facility-service';



@NgModule({
  declarations: [
    MyApp
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    HttpClientModule,
    CustomLoadingComponentModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.serviceWorkerRegister
      }
    )
 ],
  bootstrap: [MyApp],
  providers: [
    {provide: ErrorHandler, useClass: ErrorHandler},
    StatusBar,
    SplashScreen,
    AndroidPermissions,
    LoadingProvider,
    ReservationsGetScheduleProvider,
    AppMonitorV2ServiceProvider,
    StationV2ServiceProvider,
    UserV2ServiceProvider,
    MessageLoader,
    UserVehicleV2ServiceProvider,
    SavePageInfo,
    CacheUpdateService,
    StaticTableLoader,
    ManageStorageAroundReservationCardsService,
    FacilitySettingServiceProvider,
    FacilityInformationServiceProvider,
    FacilityStatusTypeServiceProvider,
    FacilitySummaryViewHistoryServiceProvider,
    FacilityDetailViewHistoryServiceProvider,
    FacilityReserveHistoryServiceProvider,
    FacilityService
  ]
})
export class AppModule {}
